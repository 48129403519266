/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const NAUGHTY_WORDS = [
  "anal",
  "anus",
  "apeshit",
  "ass",
  "asshole",
  "baby juice",
  "ball kicking",
  "ball licking",
  "ballsack",
  "ball sucking",
  "bangbros",
  "bdsm",
  "big black cock",
  "big tits",
  "bitch",
  "bitches",
  "black cock",
  "blowjob",
  "blow your load",
  "blue waffle",
  "bondage",
  "boner",
  "boob",
  "boobs",
  "brown showers",
  "bukkake",
  "bullshit",
  "camgirl",
  "clitoris",
  "cock",
  "cocks",
  "coomer",
  "coon",
  "coons",
  "creampie",
  "cum",
  "cumming",
  "deepthroat",
  "dendrophilia",
  "dick",
  "dildo",
  "doggystyle",
  "double dong",
  "double penetration",
  "dry hump",
  "eat my ass",
  "ecchi",
  "ejaculation",
  "erotic",
  "faggot",
  "fellatio",
  "female squirting",
  "fingering",
  "fisting",
  "footjob",
  "futanari",
  "gang bang",
  "gay sex",
  "giant cock",
  "golden shower",
  "goodpoop",
  "grope",
  "handjob",
  "hentai",
  "hooker",
  "incest",
  "jack off",
  "jailbait",
  "jizz",
  "jugs",
  "jew",
  "kinky",
  "KKK",
  "lemon party",
  "lolita",
  "male squirting",
  "masturbate",
  "masturbates",
  "milf",
  "missionary",
  "motherfucker",
  "nazi",
  "negro",
  "neonazi",
  "nigga",
  "nigger",
  "nignog",
  "nipples",
  "nipple",
  "nsfw",
  "nude",
  "nudity",
  "nymph",
  "nymphomaniac",
  "octopussy",
  "one guy one jar",
  "orgasm",
  "orgy",
  "pedophile",
  "panties",
  "pedobear",
  "penis",
  "phone sex",
  "piece of shit",
  "pissing",
  "playboy",
  "chainsmoker",
  "ponyplay",
  "poof",
  "poon",
  "porn",
  "pornography",
  "prince albert piercing",
  "pubes",
  "pussy",
  "queef",
  "raging boner",
  "rape",
  "rapist",
  "rectum",
  "cowgirl",
  "reverse cowgirl",
  "rimjob",
  "scat",
  "scissoring",
  "semen",
  "sex",
  "sexy",
  "shemale",
  "shit",
  "shota",
  "slut",
  "smut",
  "spread legs",
  "strapon",
  "strip club",
  "suck",
  "sucks",
  "swastika",
  "tea bagging",
  "threesome",
  "tit",
  "titties",
  "titty",
  "topless",
  "tranny",
  "twat",
  "two girls one cup",
  "undressing",
  "urethra",
  "vagina",
  "vibrator",
  "vore",
  "voyeur",
  "wank",
  "wet dream",
  "white power",
  "xxx",
  "yaoi",
  "yiff",
  "zoophilia",
  "wrinkled starfish",
  "rick",
  "joe",
  "pickle rick",
  "nigga rick",
  "retard",
  "cripple",
  "sircumsalot",
  "circumcision",
  "copher",
  "cunt",
  "cooter",
  "beaver",
  "fish lips",
  "taco",
  "camel toe",
  "muff",
  "snatch",
  "fuck hole",
  "garage",
  "oven",
  "love button",
  "penis glove",
  "cock sock",
  "cock pocket",
  "JJ",
  "hoohah",
  "bajingo",
  "cum dumpster",
  "sperm bottle",
  "goop chute",
  "slit",
  "trim",
  "quim",
  "pooter",
  "love rug",
  "poontang",
  "poonanie",
  "cooch",
  "tunnel of love",
  "vertical bacon sandwich",
  "bearded clam",
  "cookie",
  "cooleyhopper",
  "nookie",
  "the pink",
  "honey pot",
  "cunny",
  "vag",
  "meat curtains",
  "hatchet wound",
  "putz",
  "fur burger",
  "box",
  "front bottom",
  "gash",
  "kebab",
  "kitty",
  "minge",
  "snapper",
  "catfish",
  "vertical smile",
  "lovebox",
  "love canal",
  "nana",
  "flower",
  "the cum dump",
  "chocha",
  "black hole",
  "sperm sucker",
  "fish sandwich",
  "flexible",
  "girl",
  "cock warmer",
  "whisker biscuit",
  "carpet",
  "deep socket",
  "cum craver",
  "cock squeezer",
  "slice of heaven",
  "flesh cavern",
  "the great divide",
  "cherry",
  "tongue depressor",
  "clit slit",
  "laps",
  "fuzz box",
  "fuzzy wuzzy",
  "glory hole",
  "grumble",
  "man in the boat",
  "mud flaps",
  "mound",
  "peach",
  "piss flaps",
  "the fish flap",
  "he furry cup",
  "stench-trench",
  "wizard's sleeve",
  "DNA dumpster",
  "tuna town",
  "split dick",
  "bikini bizkit",
  "cock holster",
  "cockpit",
  "snooch",
  "kitty kat",
  "poody tat",
  "grassy knoll",
  "cold cut combo",
  "Jewel box",
  "rosebud",
  "curly curtains",
  "furry furnace",
  "slop hole",
  "velcro love triangle",
  "nether lips",
  "where Uncle's doodle goes",
  "altar of love",
  "cupid's cupboard",
  "bird's nest",
  "bucket",
  "cock-chafer",
  "love glove",
  "serpent socket",
  "spunk-pot",
  "hairy doughnut",
  "fun hatch",
  "spasm chasm",
  "red lane",
  "stinky speedway",
  "bacon hole",
  "belly entrance",
  "sugar basin",
  "sweet briar",
  "breakfast of champions",
  "wookie",
  "fish mitten",
  "fuck pocket",
  "hump hole",
  "pink circle",
  "silk igloo",
  "scrambled eggs between the legs",
  "black oak",
  "Republic of Labia",
  "juice box",
  "Golden Palace",
  "fetus flaps",
  "skins",
  "sausage wallet",
  "Holiest of Holies",
  "sugar hole",
  "The Death of Adam",
  "home plate",
  "Deer Hoof",
  "Golden Arches",
  "Cats Paw",
  "Mule Nose",
  "Yo Yo Smuggler",
  "Mumbler",
  "Dinner Roll",
  "Crotch Waffle",
  "Piss Fenders",
  "crack",
  "Melvin",
  "Dove Breast",
  "Brakepads",
  "Vedgie",
  "Slurpy",
  "Vacuum Vulva",
  "Pastrami Flaps",
  "Hot Tamaki Walk",
  "Buffalo Gums",
  "Rooster Jaws",
  "Wagon Ruts",
  "Beaver Teeth",
  "Mumble Pants",
  "Ninja Boot",
  "Marcia",
  "Skin Canoe",
  "Fatty",
  "Mossy Jaw",
  "The Big W",
  "Chia Hole",
  "Lip Jeans",
  "Beetle Hood",
  "Hungry Minge",
  "Welly Top",
  "Frum",
  "Pancake Fold",
  "Tongue Roll",
  "Bologna Flap-Over",
  "Furrogi (Poland)",
  "Fortune Nookie",
  "Bearded Taco",
  "Calamari Cockring",
  "Displabia",
  "Slot Pocket",
  "Bluntfrunt",
  "Fishamjig",
  "Pole Magnet",
  "Pocket Pie",
  "Clamarama",
  "kitty cage",
  "Chicken's tongue",
  "Conch shell",
  "Crack of heaven",
  "Dog's mouth",
  "Door of life",
  "Fly catcher",
  "Fruit cup",
  "Jelly roll",
  "Lobster pot",
  "bunny tuft",
  "knish",
  "her asshole neighbor",
  "lotus",
  "nappy dugout",
  "moneymaker",
  "womens weapon",
  "tackle box",
  "bone hider",
  "red sea",
  "pizzo",
  "jizz recepticle",
  "The Helmut Hide-A-Way",
  "hairy heaven",
  "furry 8 ball rack",
  "crave cave",
  "arbys with fur",
  "fish canyon",
  "toolshed",
  "snake charmer",
  "Furby",
  "Enchilada of love",
  "Ham sandwich",
  "Camarillo brillo",
  "Brazilian caterpillar",
  "dick rack",
  "boy in the canoe",
  "flesh tuxedo",
  "Mound of Venus",
  "queef quarters",
  "Venus butterfly",
  "cream canal",
  "apple pie",
  "pie",
  "wet mark",
  "private area",
  "thresher",
  "punash",
  "salami garage",
  "slurpee machine",
  "pink cookie",
  "penalty box",
  "ground zero",
  "meat crease",
  "bait",
  "birth canal",
  "holy grail",
  "pole hole",
  "pork pie",
  "fuzz bucket",
  "one-eyed python trail",
  "bubble gum by the bum",
  "stink rink",
  "theme park",
  "saloon doors",
  "pink truffle",
  "bitter &amp;amp; twisted",
  "burger bar",
  "meat counter",
  "temperamental ringpiece",
  "python syphon",
  "big bud",
  "the Wombsday Book",
  "the condo downstate",
  "snake lake",
  "the indoor barbecue",
  "pound cake",
  "beef tomato",
  "tickled pink",
  "launch pad",
  "horn of plenty",
  "the indoor picnic",
  "hamper of goodies",
  "flapped bap",
  "bonefish",
  "close encounter with the turd kind",
  "sperm bank",
  "man's charity bash",
  "bush tucker",
  "midnight dip",
  "the one-door vulva",
  "the welcome opponent",
  "the Twatlantic Ocean",
  "temporary lodgings",
  "field of dreams",
  "bean",
  "cooze",
  "old catchers mitt",
  "devil's hole",
  "lucy",
  "pish buffet",
  "pooswaa",
  "poonaner",
  "davey jones locker",
  "pink panther",
  "tinker bell",
  "south mouth",
  "dick eater",
  "wonder bread",
  "wolly bolly",
  "foxhole",
  "hot pocket",
  "head catcher",
  "Lawrence of A Labia",
  "silk funnel",
  "dick driver",
  "purple people penis eater",
  "ponchita",
  "cherry pop tart",
  "fat rabbit",
  "scunt",
  "pee jaws",
  "mingus",
  "The Notorious V.A.G.",
  "dugout",
  "babyoven",
  "penis parking",
  "cooter muffin",
  "the promised land",
  "cha cha",
  "the shrine",
  "bitch ditch",
  "fury pink mink",
  "mammal hole",
  "ever-lasting cum stopper",
  "the toothless blow job",
  "happy flappy",
  "wilt chamberlian's daily glove",
  "the code defierthe salt water taffy factory",
  "mommy's pie",
  "the easy bake oven",
  "the deflower patch",
  "the virginator",
  "the schlong sucker",
  "the dea bone patch",
  "the vegitarian's temptation",
  "the vegan store",
  "the blow hole",
  "the pump protector",
  "bag pipe",
  "Spitball Bullseye",
  "meat wagon",
  "pickle stinker",
  "jezebel's smell",
  "yoni",
  "willys haven",
  "scrumpter",
  "sweat box",
  "yeast pocket",
  "penis warmer",
  "tampon tunnel",
  "penis pothole",
  "cucumber canal",
  "egg drop Box",
  "sperm shack",
  "dick dungeon",
  "cock curator",
  "b.o.b.'s bungalow",
  "mommy parts",
  "tuna pot pie",
  "nice slice",
  "peter vise",
  "rack of clam",
  "peters grove",
  "penis purse",
  "grandest canyon",
  "fish dish",
  "banana box",
  "tuna spread",
  "pink portal",
  "count fapula",
  "red river gorge",
  "happy valley",
  "revolving in/out door",
  "baby zipper",
  "richards house",
  "stop-n-pop",
  "bone polisher",
  "packin shack",
  "weiner wrap",
  "clap trap",
  "dildo hotel",
  "axe gash",
  "pearl hotel",
  "sea food six pack",
  "clam canal",
  "coose canal",
  "dick deposit",
  "wand waxer",
  "vidgie",
  "erie canal",
  "candy kiss",
  "gauntlet",
  "round mound of beehound",
  "lick n' stick",
  "lap flounder",
  "tomahawk chop",
  "chin-chin",
  "pachinko",
  "cuntry pie",
  "lip tip",
  "the big casino",
  "one eyed worm hole",
  "amazon forest",
  "cock cave",
  "fuck donut",
  "coochie pop",
  "babby",
  "wet seal",
  "pissy froth hole",
  "bald biscuit",
  "the unmentionable",
  "mans ruin",
  "peeshie",
  "hairy potter",
  "courtney cocksleve",
  "panty hamster",
  "deep pink",
  "jaws of life",
  "gizmo",
  "faith",
  "cock magnet",
  "slippery slide",
  "Meat tunnel",
  "pink heaven",
  "squid",
  "dick basket",
  "hot spot",
  "poochika",
  "pudding",
  "bowl",
  "love cave",
  "squeeze-box",
  "he bone collector",
  "goodie basket",
  "depository",
  "pink turtleneck",
  "bread-box",
  "little debbie",
  "pandora's box",
  "snail tracker",
  "cuntzilla",
  "homebase",
  "pud pocket",
  "bear trap",
  "indian bones and the temple of poon",
  "chanch",
  "big montana",
  "noochie",
  "choot",
  "golden valley",
  "nappy roots",
  "dick mitten",
  "mystical fold",
  "red bread",
  "meat locker",
  "douche luge",
  "pushin cushion",
  "Bengazi strip",
  "squishy mitten",
  "silk purse",
  "rat",
  "vagoo",
  "meat sock",
  "whispering eye",
  "ham wallet",
  "dripping delta",
  "squish mitten",
  "meat wallet",
  "slime socket",
  "pink moon",
  "muffin patch",
  "piss flippers",
  "sea mouse",
  "lady garden",
  "otter",
  "fanny",
  "vent",
  "clunge",
  "ham flaps",
  "growler",
  "va-jay-jay",
  "soft-shelled tuna taco",
  "salmon orchid",
  "sarlac pit",
  "Cupid's Quiver",
  "jade gate",
  "tuna sock",
  "bitch wrinkle",
  "fluffy wewe hole",
  "velvet glove",
  "clown car",
  "cocktease",
  "Ageplay",
  "Bottom",
  "Breathplay",
  "Cane/Caning",
  "Consent",
  "CBT",
  "CNC",
  "DDlb",
  "DDlg",
  "Dominant",
  "D/s",
  "DubCon",
  "Edgeplay",
  "Fetish",
  "Golden Showers",
  "Hard Limit",
  "Impact Play",
  "Limit",
  "Masochist",
  "MDlb",
  "MDlg",
  "M/s",
  "NonCon",
  "OTK",
  "Play",
  "Play Party",
  "S&M",
  "Sadist",
  "Sadomasochist",
  "Safeword",
  "Soft Limit",
  "Submissive",
  "Switch",
  "Top",
  "Vanilla",
  "abbo",
  "abo",
  "abortion",
  "abuse",
  "addict",
  "addicts",
  "adult",
  "africa",
  "african",
  "alla",
  "allah",
  "alligatorbait",
  "amateur",
  "american",
  "analannie",
  "analsex",
  "angie",
  "angry",
  "arab",
  "arabs",
  "areola",
  "argie",
  "aroused",
  "arse",
  "arsehole",
  "asian",
  "assassin",
  "assassinate",
  "assassination",
  "assault",
  "assbagger",
  "assblaster",
  "assclown",
  "asscowboy",
  "asses",
  "assfuck",
  "assfucker",
  "asshat",
  "assholes",
  "asshore",
  "assjockey",
  "asskiss",
  "asskisser",
  "assklown",
  "asslick",
  "asslicker",
  "asslover",
  "assman",
  "assmonkey",
  "assmunch",
  "assmuncher",
  "asspacker",
  "asspirate",
  "asspuppies",
  "assranger",
  "asswhore",
  "asswipe",
  "athletesfoot",
  "attack",
  "australian",
  "babe",
  "babies",
  "backdoor",
  "backdoorman",
  "backseat",
  "badfuck",
  "balllicker",
  "balls",
  "banging",
  "baptist",
  "barelylegal",
  "barf",
  "barface",
  "barfface",
  "bast",
  "bastard ",
  "bazongas",
  "bazooms",
  "beaner",
  "beast",
  "beastality",
  "beastial",
  "beastiality",
  "beatoff",
  "beat-off",
  "beatyourmeat",
  "bestial",
  "bestiality",
  "bi",
  "biatch",
  "bible",
  "bicurious",
  "bigass",
  "bigbastard",
  "bigbutt",
  "bigger",
  "bisexual",
  "bi-sexual",
  "bitcher",
  "bitchez",
  "bitchin",
  "bitching",
  "bitchslap",
  "bitchy",
  "biteme",
  "black",
  "blackman",
  "blackout",
  "blacks",
  "blind",
  "blow",
  "boang",
  "bogan",
  "bohunk",
  "bollick",
  "bollock",
  "bomb",
  "bombers",
  "bombing",
  "bombs",
  "bomd",
  "bong",
  "boobies",
  "booby",
  "boody",
  "boom",
  "boong",
  "boonga",
  "boonie",
  "booty",
  "bootycall",
  "bountybar",
  "bra",
  "brea5t",
  "breast",
  "breastjob",
  "breastlover",
  "breastman",
  "brothel",
  "bugger",
  "buggered",
  "buggery",
  "bullcrap",
  "bulldike",
  "bulldyke",
  "bumblefuck",
  "bumfuck",
  "bunga",
  "bunghole",
  "buried",
  "burn",
  "butchbabes",
  "butchdike",
  "butchdyke",
  "butt",
  "buttbang",
  "butt-bang",
  "buttface",
  "buttfuck",
  "butt-fuck",
  "buttfucker",
  "butt-fucker",
  "buttfuckers",
  "butt-fuckers",
  "butthead",
  "buttman",
  "buttmunch",
  "buttmuncher",
  "buttpirate",
  "buttplug",
  "buttstain",
  "byatch",
  "cacker",
  "cameljockey",
  "cameltoe",
  "canadian",
  "cancer",
  "carpetmuncher",
  "carruth",
  "catholic",
  "catholics",
  "cemetery",
  "chav",
  "cherrypopper",
  "chickslick",
  "children's",
  "chin",
  "chinaman",
  "chinamen",
  "chinese",
  "chink",
  "chinky",
  "choad",
  "chode",
  "christ",
  "christian",
  "church",
  "cigarette",
  "cigs",
  "clamdigger",
  "clamdiver",
  "clit",
  "clogwog",
  "cocaine",
  "cockblock",
  "cockblocker",
  "cockcowboy",
  "cockfight",
  "cockhead",
  "cockknob",
  "cocklicker",
  "cocklover",
  "cocknob",
  "cockqueen",
  "cockrider",
  "cocksman",
  "cocksmith",
  "cocksmoker",
  "cocksucer",
  "cocksuck ",
  "cocksucked ",
  "cocksucker",
  "cocksucking",
  "cocktail",
  "cocky",
  "cohee",
  "coitus",
  "color",
  "colored",
  "coloured",
  "commie",
  "communist",
  "condom",
  "conservative",
  "conspiracy",
  "coolie",
  "cooly",
  "coondog",
  "copulate",
  "cornhole",
  "corruption",
  "cra5h",
  "crabs",
  "crackpipe",
  "crackwhore",
  "crack-whore",
  "crap",
  "crapola",
  "crapper",
  "crappy",
  "crash",
  "creamy",
  "crime",
  "crimes",
  "criminal",
  "criminals",
  "crotch",
  "crotchjockey",
  "crotchmonkey",
  "crotchrot",
  "cumbubble",
  "cumfest",
  "cumjockey",
  "cumm",
  "cummer",
  "cumquat",
  "cumqueen",
  "cumshot",
  "cunilingus",
  "cunillingus",
  "cunn",
  "cunnilingus",
  "cunntt",
  "cunteyed",
  "cuntfuck",
  "cuntfucker",
  "cuntlick ",
  "cuntlicker ",
  "cuntlicking ",
  "cuntsucker",
  "cybersex",
  "cyberslimer",
  "dago",
  "dahmer",
  "dammit",
  "damn",
  "damnation",
  "damnit",
  "darkie",
  "darky",
  "datnigga",
  "dead",
  "deapthroat",
  "death",
  "defecate",
  "dego",
  "demon",
  "deposit",
  "desire",
  "destroy",
  "deth",
  "devil",
  "devilworshipper",
  "dickbrain",
  "dickforbrains",
  "dickhead",
  "dickless",
  "dicklick",
  "dicklicker",
  "dickman",
  "dickwad",
  "dickweed",
  "diddle",
  "die",
  "died",
  "dies",
  "dike",
  "dingleberry",
  "dink",
  "dipshit",
  "dipstick",
  "dirty",
  "disease",
  "diseases",
  "disturbed",
  "dive",
  "dix",
  "dixiedike",
  "dixiedyke",
  "doggiestyle",
  "dong",
  "doodoo",
  "doo-doo",
  "doom",
  "dope",
  "dragqueen",
  "dragqween",
  "dripdick",
  "drug",
  "drunk",
  "drunken",
  "dumb",
  "dumbass",
  "dumbbitch",
  "dumbfuck",
  "dyefly",
  "dyke",
  "easyslut",
  "eatballs",
  "eatme",
  "eatpussy",
  "ecstacy",
  "ejaculate",
  "ejaculated",
  "ejaculating ",
  "enema",
  "enemy",
  "erect",
  "erection",
  "ero",
  "escort",
  "ethiopian",
  "ethnic",
  "european",
  "evl",
  "excrement",
  "execute",
  "executed",
  "execution",
  "executioner",
  "explosion",
  "facefucker",
  "faeces",
  "fag",
  "fagging",
  "fagot",
  "failed",
  "failure",
  "fairies",
  "fairy",
  "fannyfucker",
  "fart",
  "farted ",
  "farting ",
  "farty ",
  "fastfuck",
  "fat",
  "fatah",
  "fatass",
  "fatfuck",
  "fatfucker",
  "fatso",
  "fckcum",
  "fear",
  "feces",
  "felatio ",
  "felch",
  "felcher",
  "felching",
  "feltch",
  "feltcher",
  "feltching",
  "fight",
  "filipina",
  "filipino",
  "fingerfood",
  "fingerfuck ",
  "fingerfucked ",
  "fingerfucker ",
  "fingerfuckers",
  "fingerfucking ",
  "fire",
  "firing",
  "fister",
  "fistfuck",
  "fistfucked ",
  "fistfucker ",
  "fistfucking ",
  "flange",
  "flasher",
  "flatulence",
  "floo",
  "flydie",
  "flydye",
  "fok",
  "fondle",
  "footaction",
  "footfuck",
  "footfucker",
  "footlicker",
  "footstar",
  "fore",
  "foreskin",
  "forni",
  "fornicate",
  "foursome",
  "fourtwenty",
  "fraud",
  "freakfuck",
  "freakyfucker",
  "freefuck",
  "fu",
  "fubar",
  "fuc",
  "fucck",
  "fuck",
  "fucka",
  "fuckable",
  "fuckbag",
  "fuckbuddy",
  "fucked",
  "fuckedup",
  "fucker",
  "fuckers",
  "fuckface",
  "fuckfest",
  "fuckfreak",
  "fuckfriend",
  "fuckhead",
  "fuckher",
  "fuckin",
  "fuckina",
  "fucking",
  "fuckingbitch",
  "fuckinnuts",
  "fuckinright",
  "fuckit",
  "fuckknob",
  "fuckme ",
  "fuckmehard",
  "fuckmonkey",
  "fuckoff",
  "fuckpig",
  "fucks",
  "fucktard",
  "fuckwhore",
  "fuckyou",
  "fudgepacker",
  "fugly",
  "fuk",
  "fuks",
  "funeral",
  "funfuck",
  "fungus",
  "fuuck",
  "gangbang",
  "gangbanged ",
  "gangbanger",
  "gangsta",
  "gatorbait",
  "gay",
  "gaymuthafuckinwhore",
  "gaysex ",
  "geez",
  "geezer",
  "geni",
  "genital",
  "german",
  "getiton",
  "gin",
  "ginzo",
  "gipp",
  "girls",
  "givehead",
  "glazeddonut",
  "gob",
  "god",
  "godammit",
  "goddamit",
  "goddammit",
  "goddamn",
  "goddamned",
  "goddamnes",
  "goddamnit",
  "goddamnmuthafucker",
  "goldenshower",
  "gonorrehea",
  "gonzagas",
  "gook",
  "gotohell",
  "goy",
  "goyim",
  "greaseball",
  "gringo",
  "groe",
  "gross",
  "grostulation",
  "gubba",
  "gummer",
  "gun",
  "gyp",
  "gypo",
  "gypp",
  "gyppie",
  "gyppo",
  "gyppy",
  "hamas",
  "hapa",
  "harder",
  "hardon",
  "harem",
  "headfuck",
  "headlights",
  "hebe",
  "heeb",
  "hell",
  "henhouse",
  "heroin",
  "herpes",
  "heterosexual",
  "hijack",
  "hijacker",
  "hijacking",
  "hillbillies",
  "hindoo",
  "hiscock",
  "hitler",
  "hitlerism",
  "hitlerist",
  "hiv",
  "ho",
  "hobo",
  "hodgie",
  "hoes",
  "hole",
  "holestuffer",
  "homicide",
  "homo",
  "homobangers",
  "homosexual",
  "honger",
  "honk",
  "honkers",
  "honkey",
  "honky",
  "hook",
  "hookers",
  "hooters",
  "hore",
  "hork",
  "horn",
  "horney",
  "horniest",
  "horny",
  "horseshit",
  "hosejob",
  "hoser",
  "hostage",
  "hotdamn",
  "hotpussy",
  "hottotrot",
  "hummer",
  "husky",
  "hussy",
  "hustler",
  "hymen",
  "hymie",
  "iblowu",
  "idiot",
  "ikey",
  "illegal",
  "insest",
  "intercourse",
  "interracial",
  "intheass",
  "inthebuff",
  "israel",
  "israeli",
  "israel's",
  "italiano",
  "itch",
  "jackass",
  "jackoff",
  "jackshit",
  "jacktheripper",
  "jade",
  "jap",
  "japanese",
  "japcrap",
  "jebus",
  "jeez",
  "jerkoff",
  "jesus",
  "jesuschrist",
  "jewish",
  "jiga",
  "jigaboo",
  "jigg",
  "jigga",
  "jiggabo",
  "jigger ",
  "jiggy",
  "jihad",
  "jijjiboo",
  "jimfish",
  "jism",
  "jiz ",
  "jizim",
  "jizjuice",
  "jizm ",
  "jizzim",
  "jizzum",
  "joint",
  "juggalo",
  "junglebunny",
  "kaffer",
  "kaffir",
  "kaffre",
  "kafir",
  "kanake",
  "kid",
  "kigger",
  "kike",
  "kill",
  "killed",
  "killer",
  "killing",
  "kills",
  "kink",
  "kissass",
  "knife",
  "knockers",
  "kock",
  "kondum",
  "koon",
  "kotex",
  "krap",
  "krappy",
  "kraut",
  "kum",
  "kumbubble",
  "kumbullbe",
  "kummer",
  "kumming",
  "kumquat",
  "kums",
  "kunilingus",
  "kunnilingus",
  "kunt",
  "ky",
  "kyke",
  "lactate",
  "laid",
  "lapdance",
  "latin",
  "lesbain",
  "lesbayn",
  "lesbian",
  "lesbin",
  "lesbo",
  "lez",
  "lezbe",
  "lezbefriends",
  "lezbo",
  "lezz",
  "lezzo",
  "liberal",
  "libido",
  "licker",
  "lickme",
  "lies",
  "limey",
  "limpdick",
  "limy",
  "lingerie",
  "liquor",
  "livesex",
  "loadedgun",
  "looser",
  "loser",
  "lotion",
  "lovebone",
  "lovegoo",
  "lovegun",
  "lovejuice",
  "lovemuscle",
  "lovepistol",
  "loverocket",
  "lowlife",
  "lsd",
  "lubejob",
  "lucifer",
  "luckycammeltoe",
  "lugan",
  "lynch",
  "macaca",
  "mad",
  "mafia",
  "magicwand",
  "mams",
  "manhater",
  "manpaste",
  "marijuana",
  "mastabate",
  "mastabater",
  "masterbate",
  "masterblaster",
  "mastrabator",
  "masturbating",
  "mattressprincess",
  "meatbeatter",
  "meatrack",
  "meth",
  "mexican",
  "mgger",
  "mggor",
  "mickeyfinn",
  "mideast",
  "minority",
  "mockey",
  "mockie",
  "mocky",
  "mofo",
  "moky",
  "moles",
  "molest",
  "molestation",
  "molester",
  "molestor",
  "moneyshot",
  "mooncricket",
  "mormon",
  "moron",
  "moslem",
  "mosshead",
  "mothafuck",
  "mothafucka",
  "mothafuckaz",
  "mothafucked ",
  "mothafucker",
  "mothafuckin",
  "mothafucking ",
  "mothafuckings",
  "motherfuck",
  "motherfucked",
  "motherfuckin",
  "motherfucking",
  "motherfuckings",
  "motherlovebone",
  "muffdive",
  "muffdiver",
  "muffindiver",
  "mufflikcer",
  "mulatto",
  "muncher",
  "munt",
  "murder",
  "murderer",
  "muslim",
  "naked",
  "narcotic",
  "nasty",
  "nastybitch",
  "nastyho",
  "nastyslut",
  "nastywhore",
  "necro",
  "negroes",
  "negroid",
  "negro's",
  "nig",
  "niger",
  "nigerian",
  "nigerians",
  "nigg",
  "niggah",
  "niggaracci",
  "niggard",
  "niggarded",
  "niggarding",
  "niggardliness",
  "niggardliness's",
  "niggardly",
  "niggards",
  "niggard's",
  "niggaz",
  "niggerhead",
  "niggerhole",
  "niggers",
  "nigger's",
  "niggle",
  "niggled",
  "niggles",
  "niggling",
  "nigglings",
  "niggor",
  "niggur",
  "niglet",
  "nigr",
  "nigra",
  "nigre",
  "nip",
  "nipplering",
  "nittit",
  "nlgger",
  "nlggor",
  "nofuckingway",
  "nook",
  "nookey",
  "noonan",
  "nooner",
  "nudger",
  "nuke",
  "nutfucker",
  "ontherag",
  "oral",
  "orga",
  "orgasim ",
  "orgies",
  "osama",
  "paki",
  "palesimian",
  "palestinian",
  "pansies",
  "pansy",
  "panti",
  "payo",
  "pearlnecklace",
  "peck",
  "pecker",
  "peckerwood",
  "pee",
  "peehole",
  "pee-pee",
  "peepshow",
  "peepshpw",
  "pendy",
  "penetration",
  "peni5",
  "penile",
  "penises",
  "penthouse",
  "period",
  "perv",
  "phonesex",
  "phuk",
  "phuked",
  "phuking",
  "phukked",
  "phukking",
  "phungky",
  "phuq",
  "pi55",
  "picaninny",
  "piccaninny",
  "pickaninny",
  "piker",
  "pikey",
  "piky",
  "pimp",
  "pimped",
  "pimper",
  "pimpjuic",
  "pimpjuice",
  "pimpsimp",
  "pindick",
  "piss",
  "pissed",
  "pisser",
  "pisses ",
  "pisshead",
  "pissin ",
  "pissoff ",
  "pistol",
  "pixie",
  "pixy",
  "playgirl",
  "pocha",
  "pocho",
  "pocketpool",
  "pohm",
  "polack",
  "pom",
  "pommie",
  "pommy",
  "poo",
  "poop",
  "pooper",
  "pooperscooper",
  "pooping",
  "poorwhitetrash",
  "popimp",
  "porchmonkey",
  "pornflick",
  "pornking",
  "porno",
  "pornprincess",
  "pot",
  "poverty",
  "premature",
  "pric",
  "prick",
  "prickhead",
  "primetime",
  "propaganda",
  "pros",
  "prostitute",
  "protestant",
  "pu55i",
  "pu55y",
  "pube",
  "pubic",
  "pubiclice",
  "pud",
  "pudboy",
  "pudd",
  "puddboy",
  "puke",
  "puntang",
  "purinapricness",
  "puss",
  "pussie",
  "pussies",
  "pussycat",
  "pussyeater",
  "pussyfucker",
  "pussylicker",
  "pussylips",
  "pussylover",
  "pussypounder",
  "pusy",
  "quashie",
  "queer",
  "quickie",
  "ra8s",
  "rabbi",
  "racial",
  "racist",
  "radical",
  "radicals",
  "raghead",
  "randy",
  "raped",
  "raper",
  "rearend",
  "rearentry",
  "redlight",
  "redneck",
  "reefer",
  "reestie",
  "refugee",
  "reject",
  "remains",
  "rentafuck",
  "republican",
  "rere",
  "retarded",
  "ribbed",
  "rigger",
  "rimming",
  "roach",
  "robber",
  "roundeye",
  "rump",
  "russki",
  "russkie",
  "sadis",
  "sadom",
  "samckdaddy",
  "sandm",
  "sandnigger",
  "satan",
  "scag",
  "scallywag",
  "schlong",
  "screw",
  "screwyou",
  "scrotum",
  "scum",
  "seppo",
  "servant",
  "sexed",
  "sexfarm",
  "sexhound",
  "sexhouse",
  "sexing",
  "sexkitten",
  "sexpot",
  "sexslave",
  "sextogo",
  "sextoy",
  "sextoys",
  "sexual",
  "sexually",
  "sexwhore",
  "sexymoma",
  "sexy-slim",
  "shag",
  "shaggin",
  "shagging",
  "shat",
  "shav",
  "shawtypimp",
  "sheeney",
  "shhit",
  "shinola",
  "shitcan",
  "shitdick",
  "shite",
  "shiteater",
  "shited",
  "shitface",
  "shitfaced",
  "shitfit",
  "shitforbrains",
  "shitfuck",
  "shitfucker",
  "shitfull",
  "shithapens",
  "shithappens",
  "shithead",
  "shithouse",
  "shiting",
  "shitlist",
  "shitola",
  "shitoutofluck",
  "shits",
  "shitstain",
  "shitted",
  "shitter",
  "shitting",
  "shitty ",
  "shoot",
  "shooting",
  "shortfuck",
  "showtime",
  "sick",
  "sissy",
  "sixsixsix",
  "sixtynine",
  "sixtyniner",
  "skank",
  "skankbitch",
  "skankfuck",
  "skankwhore",
  "skanky",
  "skankybitch",
  "skankywhore",
  "skinflute",
  "skum",
  "skumbag",
  "slant",
  "slanteye",
  "slapper",
  "slaughter",
  "slav",
  "slave",
  "slavedriver",
  "sleezebag",
  "sleezeball",
  "slideitin",
  "slime",
  "slimeball",
  "slimebucket",
  "slopehead",
  "slopey",
  "slopy",
  "sluts",
  "slutt",
  "slutting",
  "slutty",
  "slutwear",
  "slutwhore",
  "smack",
  "smackthemonkey",
  "snatchpatch",
  "snigger",
  "sniggered",
  "sniggering",
  "sniggers",
  "snigger's",
  "sniper",
  "snot",
  "snowback",
  "snownigger",
  "sob",
  "sodom",
  "sodomise",
  "sodomite",
  "sodomize",
  "sodomy",
  "sonofabitch",
  "sonofbitch",
  "sooty",
  "sos",
  "soviet",
  "spaghettibender",
  "spaghettinigger",
  "spank",
  "spankthemonkey",
  "sperm",
  "spermacide",
  "spermbag",
  "spermhearder",
  "spermherder",
  "spic",
  "spick",
  "spig",
  "spigotty",
  "spik",
  "spit",
  "spitter",
  "splittail",
  "spooge",
  "spreadeagle",
  "spunk",
  "spunky",
  "squaw",
  "stagg",
  "stiffy",
  "stringer",
  "stripclub",
  "stroke",
  "stroking",
  "stupid",
  "stupidfuck",
  "stupidfucker",
  "suckdick",
  "sucker",
  "suckme",
  "suckmyass",
  "suckmydick",
  "suckmytit",
  "suckoff",
  "suicide",
  "swallow",
  "swallower",
  "swalow",
  "sweetness",
  "syphilis",
  "taboo",
  "taff",
  "tampon",
  "tang",
  "tantra",
  "tarbaby",
  "tard",
  "teat",
  "terror",
  "terrorist",
  "teste",
  "testicle",
  "testicles",
  "thicklips",
  "thirdeye",
  "thirdleg",
  "threeway",
  "timbernigger",
  "tinkle",
  "titbitnipply",
  "titfuck",
  "titfucker",
  "titfuckin",
  "titjob",
  "titlicker",
  "titlover",
  "tits",
  "tittie",
  "tnt",
  "toilet",
  "tongethruster",
  "tongue",
  "tonguethrust",
  "tonguetramp",
  "tortur",
  "torture",
  "tosser",
  "towelhead",
  "trailertrash",
  "tramp",
  "trannie",
  "transexual",
  "transsexual",
  "transvestite",
  "triplex",
  "trisexual",
  "trojan",
  "trots",
  "tuckahoe",
  "tunneloflove",
  "turd",
  "turnon",
  "twink",
  "twinkie",
  "twobitwhore",
  "uck",
  "uk",
  "unfuckable",
  "upskirt",
  "uptheass",
  "upthebutt",
  "urinary",
  "urinate",
  "urine",
  "usama",
  "uterus",
  "vaginal",
  "vatican",
  "vibr",
  "vibrater",
  "vietcong",
  "violence",
  "virgin",
  "virginbreaker",
  "vomit",
  "vulva",
  "wab",
  "wanker",
  "wanking",
  "waysted",
  "weapon",
  "weenie",
  "weewee",
  "welcher",
  "welfare",
  "wetb",
  "wetback",
  "wetspot",
  "whacker",
  "whash",
  "whigger",
  "whiskey",
  "whiskeydick",
  "whiskydick",
  "whit",
  "whitenigger",
  "whites",
  "whitetrash",
  "whitey",
  "whiz",
  "whop",
  "whore",
  "whorefucker",
  "whorehouse",
  "wigger",
  "willie",
  "williewanker",
  "willy",
  "wn",
  "wog",
  "women's",
  "wop",
  "wtf",
  "wuss",
  "wuzzie",
  "xtc",
  "yankee",
  "yellowman",
  "zigabo",
  "zipperhead",
  "bastard",
  "bloody",
  "blow job",
  "bollok",
  "bum",
  "feck",
  "fellate",
  "f u c k",
  "fudge packer",
  "God damn",
  "jerk",
  "knobend",
  "knob end",
  "labia",
  "lmao",
  "lmfao",
  "omg",
  "s hit",
  "sh1t",
  "smegma",
  "queaf",
  "poop chute",
  "muffdiving",
  "girl on",
  "venus mound",
  "menage a trois",
  "busty",
  "blonde action",
  "pthc",
  "how to kill",
  "blumpkin",
  "piss pig",
  "xx",
  "style doggy",
  "fecal",
  "rusty trombone",
  "nimphomania",
  "ball gag",
  "muff diver",
  "jail bait",
  "bareback",
  "lovemaking",
  "missionary position",
  "date rape",
  "deep throat",
  "tied up",
  "make me come",
  "tight white",
  "tribadism",
  "sadism",
  "2g1c",
  "doggy style",
  "ball sack",
  "donkey punch",
  "dirty pillows",
  "juggs",
  "bastinado",
  "women rapping",
  "swinger",
  "strap on",
  "dp action",
  "homoerotic",
  "acrotomophilia",
  "sexo",
  "carpet muncher",
  "eunuch",
  "rosy palm",
  "jiggerboo",
  "doggie style",
  "pleasure chest",
  "blow j",
  "genitals",
  "vorarephilia",
  "coprolagnia",
  "humping",
  "suicide girls",
  "pole smoker",
  "shrimping",
  "big knockers",
  "how to murder",
  "auto erotic",
  "sultry women",
  "barenaked",
  "wrapping men",
  "huge fat",
  "big breasts",
  "fuck buttons",
  "butthole",
  "tushy",
  "big black",
  "daterape",
  "shibari",
  "jiggaboo",
  "baby batter",
  "snowballing",
  "blonde on blonde action",
  "paedophile",
  "hot chick",
  "circlejerk",
  "gokkun",
  "goregasm",
  "2 girls 1 cup",
  "beaver cleaver",
  "dominatrix",
  "brunette action",
  "one cup two girls",
  "g-spot",
  "barely legal",
  "bastardo",
  "bbw",
  "leather restraint",
  "poopchute",
  "rosy palm and her 5 sisters",
  "goatse",
  "nig nog",
  "babeland",
  "dommes",
  "femdom",
  "cleveland steamer",
  "beaver lips",
  "jerk off",
  "shaved beaver",
  "clusterfuck",
  "raping",
  "hardcore",
  "throating",
  "leather straight jacket",
  "goo girl",
  "knobbing",
  "urethra play",
  "shaved pussy",
  "kinkster",
  "buttcheeks",
  "violet blue",
  "bollocks",
  "goatcx",
  "urophilia",
  "omorashi",
  "birdlock",
  "nambla",
  "chocolate rosebuds",
  "domination",
  "nympho",
  "nsfw images",
  "dirty sanchez",
  "mr hands",
  "bi curious",
  "clover clamps",
  "nymphomania",
  "yellow showers",
  "girl on top",
  "erotism",
  "tainted love",
  "ball gravy",
  "anilingus",
  "frotting",
  "nawashi",
  "bung hole",
  "camwhore",
  "tubgirl",
  "blow your l",
  "bimbos",
  "booty call",
  "strappado",
  "panty",
  "foot fetish",
  "hand job",
  "girls gone wild",
  "autoerotic",
  "group sex",
  "pegging",
  "taste my",
  "tub girl",
  "hard core",
  "figging",
  "kinbaku",
  "violet wand",
  "ethical slut",
  "dolcett",
  "dog style",
  "tongue in a",
  "yiffy",
  "bullet vibe",
  "pisspig",
  "coprophilia",
  "guro",
  "camslut",
  "alabama hot pocket",
  "alaskan pipeline",
  "bangbus",
  "beaners",
  "cialis",
  "cumshots",
  "dingleberries",
  "dvda",
  "fingerbang",
  "fucktards",
  "hot carl",
  "jelly donut",
  "masturbation",
  "mong",
  "nutten",
  "punany",
  "santorum",
  "sexcam",
  "sexuality",
  "shitblimp",
  "shitty",
  "skeet",
  "spastic",
  "splooge",
  "splooge moose",
  "thumbzilla",
  "viagra",
  "voyeurweb",
  "voyuer",
  "worldsex",
  "4r5e",
  "5h1t",
  "5hit",
  "a55",
  "ar5e",
  "arrse",
  "ass-fucker",
  "assfukka",
  "asswhole",
  "a_s_s",
  "b!tch",
  "b00bs",
  "b17ch",
  "b1tch",
  "ballbag",
  "bellend",
  "bi+ch",
  "bitchers",
  "blowjobs",
  "boiolas",
  "booobs",
  "boooobs",
  "booooobs",
  "booooooobs",
  "breasts",
  "buceta",
  "bunny fucker",
  "buttmuch",
  "c0ck",
  "c0cksucker",
  "cawk",
  "cipa",
  "cl1t",
  "clits",
  "cnut",
  "cock-sucker",
  "cockface",
  "cockmunch",
  "cockmuncher",
  "cocksucks ",
  "cocksuka",
  "cocksukka",
  "cok",
  "cokmuncher",
  "coksucka",
  "cox",
  "cums",
  "cunts",
  "cyalis",
  "cyberfuc",
  "cyberfuck ",
  "cyberfucked ",
  "cyberfucker",
  "cyberfuckers",
  "cyberfucking ",
  "d1ck",
  "dildos",
  "dinks",
  "dirsa",
  "dlck",
  "dog-fucker",
  "doggin",
  "dogging",
  "donkeyribber",
  "doosh",
  "duche",
  "ejaculates ",
  "ejaculatings",
  "ejakulate",
  "f u c k e r",
  "f4nny",
  "faggitt",
  "faggs",
  "fagots",
  "fags",
  "fannyflaps",
  "fanyy",
  "fcuk",
  "fcuker",
  "fcuking",
  "fecker",
  "fingerfucks ",
  "fistfuckers ",
  "fistfuckings ",
  "fistfucks ",
  "fook",
  "fooker",
  "fuckheads",
  "fuckings",
  "fuckingshitmotherfucker",
  "fuckwhit",
  "fuckwit",
  "fuker",
  "fukker",
  "fukkin",
  "fukwhit",
  "fukwit",
  "fux",
  "fux0r",
  "f_u_c_k",
  "gangbangs ",
  "gaylord",
  "gaysex",
  "god-dam",
  "god-damned",
  "hardcoresex ",
  "heshe",
  "hoar",
  "hoare",
  "hoer",
  "hotsex",
  "jack-off ",
  "jerk-off ",
  "kawk",
  "knob",
  "knobead",
  "knobed",
  "knobhead",
  "knobjocky",
  "knobjokey",
  "kondums",
  "l3i+ch",
  "l3itch",
  "lust",
  "lusting",
  "m0f0",
  "m0fo",
  "m45terbate",
  "ma5terb8",
  "ma5terbate",
  "master-bate",
  "masterb8",
  "masterbat*",
  "masterbat3",
  "masterbation",
  "masterbations",
  "mo-fo",
  "mof0",
  "mothafuckas",
  "mothafuckers",
  "mothafucks",
  "mother fucker",
  "motherfuckers",
  "motherfuckka",
  "motherfucks",
  "mutha",
  "muthafecker",
  "muthafuckker",
  "muther",
  "mutherfucker",
  "n1gga",
  "n1gger",
  "nigg3r",
  "nigg4h",
  "niggas",
  "niggers ",
  "nob",
  "nob jokey",
  "nobhead",
  "nobjocky",
  "nobjokey",
  "numbnuts",
  "nutsack",
  "orgasims ",
  "orgasms ",
  "p0rn",
  "pawn",
  "penisfucker",
  "phuck",
  "phuks",
  "pigfucker",
  "pimpis",
  "pissers",
  "pissflaps",
  "pornos",
  "pricks ",
  "pron",
  "pusse",
  "pussi",
  "pussys ",
  "rimjaw",
  "s.o.b.",
  "screwing",
  "scroat",
  "scrote",
  "sh!+",
  "sh!t",
  "shagger",
  "shi+",
  "shitey",
  "shitings",
  "shitters ",
  "shittings",
  "son-of-a-bitch",
  "spac",
  "s_h_i_t",
  "t1tt1e5",
  "t1tties",
  "teets",
  "teez",
  "testical",
  "titt",
  "tittie5",
  "tittiefucker",
  "tittyfuck",
  "tittywank",
  "titwank",
  "tw4t",
  "twathead",
  "twatty",
  "twunt",
  "twunter",
  "v14gra",
  "v1gra",
  "w00se",
  "wang",
  "wanky",
  "whoar",
  "willies",
  "xrated",
].map((s) => s.toLowerCase())